import { Table, TableContainer, TableHead, TableRow, TableBody, IconButton, Pagination, Stack, TextField, InputAdornment } from "@mui/material";
import { SwitchContainerGTS } from "../../atoms/Controls/SwitchGTS";
import ButtonGST from "../../atoms/Controls/ButtonGTS";
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from "@mui/icons-material/Search";
import React, { useEffect, useState } from "react";
import { StyledTableCell, StyledTableRow } from "../../atoms/tables/TableStyles"
import { getServiceData } from "../../store/global-services";
import { useNavigate } from "react-router-dom";
import { UrlSiteBase } from '../../Global';
import { IGlobalPoprop, IServiceData } from "../../interfaces/global-interface";
let { UrlBase } = UrlSiteBase();


const ListadoDistribuidoras = (globalProp?: IGlobalPoprop) => {
    const [distribuidoras, setDistribuidoras] = useState<any>();
    const [textSearch, setDistribuidorasBusqueda] = useState<string>('');
    const [page, setPage] = useState<number>(1);
    const [totalPages, setTotalPages] = useState<number>(0); 
    const navigate = useNavigate();

    const handlePaginationChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
    };
    const handleSearchTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        setDistribuidorasBusqueda(value);
    }
    const getDistribuidoras = (page: number) => {
        let dataService: IServiceData  = { nombre: 'distribuidoras' };
        let paramService = {};
        if (page > 1) 
            paramService = { ...paramService, 'page': page };
        if (textSearch.trim().length > 0)
            paramService = { ...paramService, 'search': textSearch.trim() };
        dataService = { ...dataService, 'parametros': paramService };
        getServiceData(dataService).then(data => {
            if (data.success) {
                setDistribuidoras(data.distribuidoras);
                setTotalPages(data.pagination.last_page);
            }
        });
    };
    useEffect(() => {
        getDistribuidoras(page);
    }, [page]);
    const handleClick = (idDistribuidora: number) => {
        navigate(idDistribuidora > 0 ? `${UrlBase}Distribuidora/Editar/${idDistribuidora}` : `${UrlBase}Distribuidora/Agregar`);
    };
    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            getDistribuidorasSearch();
            event.preventDefault();
        }   
    };
    const getDistribuidorasSearch = () => {
        getDistribuidoras(1);
    };
    return (
        <>
        <div className="panel-search">
            <TextField id="search" onChange={handleSearchTextChange} value={textSearch} label="Buscar" color="secondary"
            InputProps={{ endAdornment: 
                <InputAdornment position="end">
                <IconButton onClick={getDistribuidorasSearch}>
                    <SearchIcon />
                </IconButton>
                </InputAdornment> }} onKeyDown={handleKeyDown}/>
        </div>
        <div className="panel-actions">
            <ButtonGST onClick={() => handleClick(0)}>Agregar <AddIcon /></ButtonGST>
        </div>
        <TableContainer>
            <Table className="table-gts">
                <TableHead>
                    <TableRow>
                        <StyledTableCell>Clave</StyledTableCell>
                        <StyledTableCell>Nombre</StyledTableCell>
                        <StyledTableCell className="movile-hidden">Razon Social</StyledTableCell>
                        <StyledTableCell >Telefonos</StyledTableCell>
                        <StyledTableCell className="movile-hidden">Email</StyledTableCell>
                        <StyledTableCell className="movile-hidden">Mapa</StyledTableCell>
                        <StyledTableCell >Estatus</StyledTableCell>
                        <StyledTableCell >Acciones</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {distribuidoras?.map((distribuidora: any) => (
                        <StyledTableRow key={distribuidora.id}>
                            <StyledTableCell>{distribuidora?.clave}</StyledTableCell>
                            <StyledTableCell>{distribuidora?.nombre}</StyledTableCell>
                            <StyledTableCell className="movile-hidden">{distribuidora?.razonSocial}</StyledTableCell>
                            <StyledTableCell>{distribuidora?.telefonos}</StyledTableCell>
                            <StyledTableCell className="movile-hidden">{distribuidora?.email}</StyledTableCell>
                            <StyledTableCell className="movile-hidden">
                            {distribuidora?.latitud !== 0 && distribuidora?.longitud !== 0 ?
                                <a href={`https://maps.google.com/?q=${distribuidora?.latitud},${distribuidora?.longitud}`} target="_blank">Ver Mapa</a>
                                : <></>}
                            </StyledTableCell>
                            <StyledTableCell><SwitchContainerGTS checked={distribuidora?.estatus === 1}  /></StyledTableCell>
                            <StyledTableCell>
                                <ButtonGST onClick={() => handleClick(distribuidora.id)}><EditIcon /></ButtonGST>
                            </StyledTableCell>
                        </StyledTableRow>
                    ))} 
                </TableBody>
            </Table>
        </TableContainer>
        { totalPages > 1 ?
            <Stack spacing={2}>
            <Pagination count={totalPages} page={page} onChange={handlePaginationChange} />
            </Stack>
            : <></>
        }
        </>
    )
};

export default ListadoDistribuidoras;