import '../../css/Login.css';
import { InputAdornment, IconButton, TextField } from "@mui/material";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import React, { useContext, useState } from 'react';
import CardAtom from '../../atoms/Card/Card';
import AuthContext from '../../store/auth-context';
import ButtonGST from "../../atoms/Controls/ButtonGTS";
import Form from 'react-bootstrap/Form';
import { IGlobalPoprop } from "../../interfaces/global-interface";
import { useNavigate  } from 'react-router-dom';
import { UrlSiteBase } from '../../Global';

function Login(globalProp?: IGlobalPoprop) {
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };
  let { UrlBase } = UrlSiteBase();
  const authContext = useContext(AuthContext);
  const onLogin = (data: any) => {
    if (!data.success && globalProp && globalProp.snackHandleOpen)
      globalProp.snackHandleOpen({open: true, message: data.message, color: "red"});
    if (globalProp && globalProp.loginHandler)
      globalProp.loginHandler(data);
    if (data.success)
      navigate(UrlBase + 'homepage');
    
  }
  const submitHandler = (e:any) => {
    authContext.onLogin(enteredEmail, enteredPassword, onLogin);
  }
  
  const [enteredEmail, setEnteredEmail] = useState('');
  const [enteredPassword, setEnteredPassword] = useState('');

  const emailChangeHandler = (event: any) => {
    setEnteredEmail(event.target.value)
  }

  const passwordChangeHandler = (event: any) => {
    setEnteredPassword(event.target.value)
  }
  return (
    <>
    <CardAtom>
      <Form className='form-gts login'>
        <Form.Group className='mb-3' controlId='email'>
          <TextField id="usuario" onChange={emailChangeHandler} value={enteredEmail} label="Usuario" color="secondary" />
        </Form.Group>
        <Form.Group className='mb-3' controlId='email'>
          <TextField id="password" onChange={passwordChangeHandler} type={showPassword ? "text" : "password"} value={enteredPassword} label="Contraseña" color="secondary"
            InputProps={{ endAdornment: 
              <InputAdornment position="end">
                <IconButton onClick={handleClickShowPassword} edge="end" onMouseDown={handleMouseDownPassword}>
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment> }} 
          />
        </Form.Group>
        <div className="panel-actions">
          <ButtonGST onClick={submitHandler}>Ingresar</ButtonGST>
        </div>
      </Form>
    </CardAtom>
    </>
  )
}

export default Login;